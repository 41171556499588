var Gantt = { _seed: 0 };
Gantt.plugin = function(code){
	this._ganttPlugin.push(code);
	code(window.gantt);
};
Gantt._ganttPlugin = [];
Gantt.getGanttInstance = function() {
	var gantt = require("./gantt")();
	for (var i=0; i<Gantt._ganttPlugin.length; i++) {
		Gantt._ganttPlugin[i](gantt);
	}
	gantt._internal_id = (Gantt._seed++);

	if (Gantt.$syncFactory)
		Gantt.$syncFactory(gantt);

	require("./deprecated_warnings")(gantt);

	return gantt;
};

module.exports = Gantt;